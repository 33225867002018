import React from "react"
import { Link } from "gatsby"
import { Helmet } from 'react-helmet'
import Layout from "../components/layout"
import SEO from "../components/seo"
import {
    Tabs,
    TabList,
    Tab,
    TabPanel,
  } from '../components/tabs'
  import TwoColumn from "../components/two-column"
import SingleContent from "../components/single-column"
import Img from "../components/image-transform"

const developersContent = {
    title: "Kickstart your career with a six month paid job at billsby.",
    description: [
      {
        text: <>Get the skills, tools and experience you need for a high tech career with the flexibility, responsibility and trust that comes with working at home. We're accepting applications until <strong>November 7th</strong> for a <strong>December 1st</strong> start.</>
      }
    ],
    ApplyNowButton: true,
    isBanner: true,
    sectionDevelopers: true,
    image: 'home-developers.png',
    imageName: 'developers svg',
    backgroundColor: '#243F5C',
    textColor: '#fff',
    imagePosition: 'right'
  }

  const tabContent = [
    {
      tabTitle: "Sales Kickstarter",
      heading: "We're hiring 10 Sales Kickstarters, who'll report into Mark Howson, our Head of Product and Business Development.",
      leftHolder: <>
        <p>Billsby is amazing, but we need to make sure our prospects know it and know we exist! Our sales kickstarters will join our business development team, and be responsible for helping everyone learn about Billsby.</p>
        <p>You might be demonstrating Billsby through Zoom demonstrations and phone calls on your work laptop, creating sales collateral and pitch decks to support deals, acting as a scribe in business meetings, answering pre-sales questions or reaching out to customers who might be interested in Billsby on social media.</p>
        <p>To succeed at this role, you'll need to be really confident in front of others - and happy</p>
      </>,
      rightHolder: <>
        <p>to jump on webcam and professionally represent Billsby to clients all over the world. You'll need to be really good at understanding how things work and quickly picking up on how to best explain things to people. </p>
        <p>Sometimes, you might be doing outreach too - calling people who've only just signed up and might not be expecting you on the other end of the line - so having a friendly and persuasive phone manner is really important. </p>
        <p>You might enjoy this role if you've got experience in sales or customer service, but if you haven't don't worry - we'll assess your raw skills at interview and during the application process.</p>
      </>
    },
    {
        tabTitle: "Success Kickstarter",
        heading: "We're hiring 10 Success Kickstarters, who'll report into Alex Russell, our Customer Success Manager.",
        leftHolder: <>
            <p>Once customers sign up, it's only the start of the story. Billsby's Customer Success team are responsible for helping customers make the most of our product - answering their support questions and maintaining our position as the most likely to be recommended billing software in the world. It's a big responsibility. But you're up for the challenge, right?</p>
            <p>You'll support our customers through Intercom (our chat tool) and outbound call requests, manage feature requests and respond to customers on our support forum. You'll also back up our sales team on calls when your knowhow and expertise is needed, and get involved with the delivery of professional services work.</p>
        </>,
        rightHolder: <>
            <p>You might end up thinking of yourself as the ultimate link between the operational side of the business and what our customers want and need - their advocate, but also a gatekeeper to make sure only real and important issues take up the time of our busy operations staff. </p>
            <p>You might also get involved with improving our processes, handling things like migrations when customers want to switch billing platforms and keeping an eye on the competition. Can't be having them surprising us, after all.</p>
            <p>You might enjoy this role if you've got experience in customer service, but if you haven't don't worry - we'll assess your raw skills at interview and during the application process. It'll help if you're deeply analytical and really enjoy getting into the weeds of a problem.</p>
        </>
    },
    {
        tabTitle: "Ops Kickstarter",
        heading: "We're hiring 10 Ops Kickstarters, who'll report into Gemma Beeharee, our Head of Global Operations.",
        leftHolder: <>
            <p>Want to know a secret? Without our Operations team, the whole of Billsby would basically collapse overnight. There's millions of lines of code, hundreds of features and loads of points of potential failure. That's where you come in. </p>
            <p>Our Operations Kickstarters will be responsible for testing the product and trying to break it. If you're a 'Sure, that worked, but what happens if...' kind of person, you'll love the challenge of finding issues our Development team missed and the satisfaction of seeing them resolved. You'll need an eye for detail, a flair for the specific and the ability to document everything you did to make the system crumble in specific detail - and a sense of ownership to make sure it's working when we tell you it's been fixed.</p>
        </>,
        rightHolder: <>
            <p>You'll also ensure the stable and successful release of new features by improving our documentation and release notes, rating new customers in the BI system, making sense of all of our data and reporting and keeping everyone across the organisation on-track.</p>
            <p>What skills do you need? Think part project manager, part Excel guru and part politician and diplomat. If you like to keep your eye on a bunch of cogs, you're probably an excellent fit. </p>
            <p>But if you don't have those abilities documented - don't worry. We'll assess your raw talent and potential during the application process and interview.</p>
        </>
    },
  ]

  const singleContent =   {
    title: <>We'd love to have you on board</>,
    text: "We can only accept applications where you've been introduced to us by your job coach - if you're currently on Universal Credit and are eligible for Kickstart you should speak to your job coach about our roles before you apply.",
    ApplyButton: true,
  }

  const singleContent2 =   {
    title: <>Picked your favourite role? <span>Then let's get this show on the road</span></>,
    text: "We can only accept applications where you've been introduced to us by your job coach - if your're currently on Universal Credit and are eligible for Kickstart you should speak to ypur job coach about roles before you apply.",
    ApplyButton: true,
  }

  const equippedContent = {
      heading: 'Getting you equipped for your next step',
      subText: "Your Kickstart at Billsby isn't just about what you can do for us - it's also about what we can do for you. We've got big plans to help you grow and develop your skills ready for your next adventure - and real rewards when you do well.",
      details: [
          {
              title: 'A laptop, Microsoft 365 for a year and CV writing software on us ',
              text: "We'll issue you with a brand new company laptop when you start as a Kickstarter with Microsoft 365 and CV writing software ready to go. As long as you complete your six month placement, it'll be yours to keep to help you apply for jobs or succeed in your next role."
          },
          {
              title: "A £100 interview kit bonus ",
              text: "As long as you achieve at least 95% attendance throughout your placement (excluding holidays, of course!) at the end of month five we'll reward you with a £100 voucher for ASOS - so you can get yourself dressed to impressed for interviews and hit the ground running wherever you head next."
          },
          {
              title: "Fixed three day work pattern ",
              text: "Throughout your placement, you'll work the same three days each week (excluding training days) - and the same hours pattern too. So if you're able to find other work alongside your placement, you can do it and not have to drop out. For example, you might work Tuesday, Thursday and Sunday between 12 noon and 9pm with a 40 minute lunch break each day."
          },
          {
              title: "The guidance and expertise of our global team ",
              text: "We'll put on events throughout your placement where our global leadership team will share their career stories, and at the end, we'll all get together for an employability and interview skills workshop, too. Plus effective line management will help you develop essential skills, and we'll be more than happy to provide a reference."
          }
      ]
  }

const kickStart = {
    image: 'kickstart.png',
    imageName: 'kickstart logo'
}

const KickStart = () => (
  <Layout className="kickstart-page">
    <TwoColumn content={developersContent}/>
    <div className="kickstart-scheme-wrapper">
        <div className="container">
            <p className="heading">Your next job could start exactly where you're sitting</p>
            <p className="text">We're hiring Kickstarters across our organisation for three exciting roles. And the best part is you'll be able to do this job from the comfort of your own home. If you've got a broadband connection and you're eager to learn and work hard, we want your application.</p>
            <div className="image-holder">
                <p className="text">Funded by the
                    <Img filename={kickStart.image} className="kickstart-logo" alt={kickStart.imageName}/>
                </p>
            </div>
            <p className="text">All positions pay the National Minimum Wage and are 25 hours per week worked over three fixed 9 hour days with a 40 minute unpaid lunchbreak. Shift patterns are 10am to 7pm, 12pm to 9pm and 2pm to 11pm. You'll be asked about your preferences when you apply. A reward package including a brand new laptop, Microsoft 365, CV writing software and a £100 ASOS voucher is available. Over the course of your six month placement, you'll get 10 days of paid holiday (not pro-rata - ten days off!)</p>
        </div>
    </div>

    <div className="tab-holder">
        <div className="container">
            <Tabs initialValue={0}>
                <TabList>
                {
                    tabContent.map((link, i) => (
                    <Tab name={i} key={i}>
                        <div className="link">{link.tabTitle}</div>
                    </Tab>
                    ))
                }
                </TabList>

                {
                tabContent.map((content, j) => (
                    <TabPanel name="application" key={j} name={j}>
                    <div className="security-content">
                        <div className="tab-body-holder">
                            <div className="text-holder">
                                <p className="text-bold">{content.heading}</p>
                                {content.leftHolder}
                            </div>
                            <div className="text-holder">
                                {content.rightHolder}
                            </div>
                        </div>
                    </div>
                    </TabPanel>
                ))
                }
            </Tabs>
        </div>
    </div>
    <SingleContent content={singleContent} />

    <div className="equipped-holder">
        <div className="container">
            <h2 className="heading">{equippedContent.heading}</h2>
            <p className="text-center">{equippedContent.subText}</p>

            {
                equippedContent.details.map((item, m) => (
                    <div className="text-holder" key={m}>
                        <p className="title">{item.title}</p>
                        <p>{item.text}</p>
                    </div>
                ))
            }
        </div>
    </div>

    <div className="explaining-story">
        <div classname="container">
            <h2>Whatever your background, you belong here</h2>
            <div className="bond-paper">
                <p>Us offering this scheme is personal to me. I left school at sixteen, started a college course I didn't enjoy and dropped out. I hustled through working at McDonalds, working for a bank, retail jobs and more before getting involved with a start-up and progressing my career to where I am today. <strong>So if you don't feel like you have the skills on paper to achieve in this role, apply anyway.</strong> </p>

                <p>We've hired incredible people straight out of university and progressed them to senior roles in our business - some of our best employees have had a degree and then found it really difficult to find work. And right now, those odds are made even harder by the situation in the world. <strong>So if you feel like it's not worth applying because you've hit the apply button 100 times before, apply anyway.</strong></p>

                <p>We have an international team of people from all across the world - English, Spanish, Italian, German, Mauritian, Filipino... Northern. People from all across the world either work for, or have worked for Billsby. <strong>So if you don't think you'd 'fit in' at a high tech startup, apply anyway. </strong></p>

                <p>We have straight employees, LGBT+ employees, employees with families, employees that live in shared houses and employees that just want to get out to the beach and surf. <strong>So whatever you love, whoever you love and whatever your passions, apply anyway. </strong></p>

                <p>We're here to help. We're here to develop your skills. We're here to give you the training you need and the experience you need to go out and accomplish anything in your life, regardless of your background, your education or your interests. </p>

                <p>But we can't help if you don't take the next step, hit the apply button and tell us why you'd be a great fit for Billsby. I hope you do, and I can't wait to speak to as many of you as I can.</p>
                <div className="feature-sign-holder">
                <img src={require("../images/mark.svg")} alt="mark" />

                <div className="sign-holder">
                    <img className="sign-logo" src={require("../images/feature-tags-letter-signature.svg")} alt="mark" />
                    <p>Head of Product and Business Development</p>
                </div>
                </div>
            </div>
        </div>
    </div>
    <SingleContent content={singleContent2} />
    <Helmet>
        <script 
            type='text/javascript'
            async={false}
            >
            {
                `
                (function() { var script = document.createElement('script'); script.src = "https://paperform.co/__embed";document.body.appendChild(script); })()
                `
            }
        </script>

    </Helmet>
    {/* <script
        dangerouslySetInnerHTML={{
            __html: `
                (function() { var script = document.createElement('script'); script.src = "https://paperform.co/__embed";document.body.appendChild(script); })()
            `,
        }}
    /> */}
  </Layout>
)

export default KickStart


